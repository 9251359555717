@import "./Style.scss";

.cube {
    position: absolute;
    top: -5vh;
    left: 4vw;
    width: 10px;
    height: 10px;
    border: solid 1px darken($elHadarGold, 10%);
    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    animation: cube 2s ease-in forwards;
    z-index: -1000 !important;
}

@keyframes cube {
    from {
        transform: scale(10) rotate(0deg) translate(-50%, -50%);
        opacity: 0;
    }
    to {
        transform: scale(20) rotate(160deg) translate(-50%, -50%);
        opacity: 0.4;
    }
}
