#specialTable {
    font-family: Arial, Helvetica, sans-serif;
    padding: 1ch;
    box-shadow: 0px 0px 10px silver;
    border-collapse: collapse;
    width: 100%;
}
#specialTable tr:nth-child(even) {
    background: whitesmoke;
}
#specialTable td {
    padding: 2ch 4ch 2ch 4ch;
    margin: 0;
    text-align: center;
    border: 1px solid #ddd;
    padding: 1.5ch;
}
#specialTable th {
    background-color: #343a40;
    padding: 2ch 4ch 2ch 4ch;
    margin: 0;
    color: #fcf5fd;
    border: 1px solid #ddd;
    padding: 1.5ch;
}
.separatorRow {
    background-color: lightgray;
}
.specialButton {
    background-color: #4caf50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 2ch 2px;
    cursor: pointer;
    background-color: white;
    color: black;
    border: 2px solid lightgray;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    width: 19ch;
}
.specialButton:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.24), 0 0 10px 0 rgba(0, 0, 0, 0.19);
}
.specialButton:disabled {
    box-shadow: 0 0 0 0 white;
    color: darkgrey;
}
.specialInput {
    height: 5ch;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    transition: 0.5s;
    outline: none;
}
.specialInput:focus {
    border: 3px solid #555;
}
