@import "./Style.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.LogInPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    // background-color: #f5f5f5;
    .logInBox {
        min-width: 50%;
        max-width: 90%;
        display: flex;
        flex-direction: row;
        // box-shadow: rgb(224, 224, 224) 0 0 20px;
        border: none;
        .LogoTop {
            display: none;
        }
        .SideDisplay {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 90%;
            // background: linear-gradient(250deg, white 40%, $mainLightBlue 100%);
            .logo {
                transition: all 2s ease-in-out;
                animation-name: pulse;
                animation-duration: 10s;
                animation-iteration-count: infinite;
            }
            .Title {
                color: $elHadarGold;
                font-weight: 500;
                font-size: large;
                font-family: "Montserrat", sans-serif;
            }
        }
        .LogInForm {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

@keyframes pulse {
    0% {
        transform: none;
    }
    50% {
        transform: scale(1.2);
    }
    0% {
        transform: none;
    }
}

@media screen and (max-width: 481px) {
    .LogInPage {
        .logInBox {
            width: 80%;
            .SideDisplay {
                display: none;
            }
            .LogoTop {
                display: block;
            }
        }
    }
}
