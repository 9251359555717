@import "./Style.scss";

.FormContainer {
    background-color: white;
    padding: 5ch;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #e6e6e6;
}

form {
    display: flex;
    flex-direction: column;
    gap: 2ch;
}
@media screen and (max-width: 481px) {
    .FormContainer {
        background-color: white;
        padding: 2ch;
        border-radius: 10px;
        box-shadow: 0px 0px 10px #e6e6e6;
    }
}
