@import "Style";

.MainScene {
    // display: flex;
    // flex-direction: column;
    width: 100%;
    background-color: whitesmoke;
    .Header {
        position: fixed;
        border-radius: 0;
        width: 100%;
        padding: 2ch;
        height: 10ch;
        // border-bottom: solid silver 1px;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        z-index: 100;
        .Profile {
            float: right;
            padding-right: 5ch;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .Logo:hover {
            transform-origin: center;
            transform: rotate(359deg);
            transition-duration: 2s;
            transition-timing-function: ease-out;
        }
    }
    .BodyContainer {
        margin-top: 9ch;
        // display: grid;
        // grid-template-columns: 15% 85%;
        display: flex;
        flex-direction: row;
        .SideBar {
            max-width: 15%;
            position: sticky;
            top: 9ch;
            // background-color: $darkBlue;
            // background: rgb(10, 17, 40);
            background: #8ab1b9;
            background: linear-gradient(
                180deg,
                // rgba(10, 17, 40, 1) 40%,
                white,
                #8ab1b9 100%
            );
            min-height: 90vh;
            max-height: 90vh;
            // border-right: solid silver 1px;
            overflow-y: scroll;
            border-radius: 0;
        }
        .Body {
            padding: 2ch;
            min-height: 90vh;
            width: 100%;
            // max-width: 100% !important;
            background-color: $smoke;
            .ViewProject {
                .SearchField {
                    max-width: 100%;
                    padding: 1ch 2ch 2ch 2ch;
                    margin-bottom: 2ch;
                    background-color: white;
                    border-radius: 5px;
                    box-shadow: 0px 0px 10px #e6e6e6;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: nowrap;

                    gap: 1ch;
                    .MuiButton-root {
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }
                .TableContainer {
                    background-color: white;
                    padding: 2ch;
                    border-radius: 5px;
                    box-shadow: 0px 0px 10px #e6e6e6;
                    min-height: 80vh;
                }
            }
        }
    }
    .MenuButton {
        display: none;
    }
}
.MuiDataGrid-columnHeaders {
    background-color: #f5f5f5;
}
.MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
}
.Dashboard {
    overflow-y: scroll;
}
.ScrollDash {
    overflow: scroll;
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
}

.ScrollDash::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

@media screen and (max-width: 1024px) {
    .EmployeeDetail {
        .Menus {
            display: none;
        }
        .MenuButton {
            display: flex;
        }
    }
}

@media screen and (max-width: 481px) {
    .MainScene {
        .BodyContainer {
            .SideBar {
                .MuiListItemButton-root {
                    margin-left: -2ch;
                }
                display: none;
            }
            .Body {
                .ViewProject {
                    .SearchField {
                        .MuiButton-root {
                            max-height: 5ch;
                            max-width: 5ch;
                            text-indent: -9999px !important;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                        .MuiButton-endIcon {
                            margin-right: 0px !important;
                            margin-left: 0px !important;
                        }
                    }
                }
            }
        }
    }
    .EmployeeDetail {
        .Menus {
            display: none;
        }
        .MuiTabPanel-root {
            padding: 1px;
        }
        .MenuButton {
            display: flex;
        }
    }
    .ModalContainer {
        width: 100% !important;
    }
    .EditModal {
        max-width: 100%;
        .MuiGrid-root {
            width: 100%;
        }
        .MuiGrid-item {
            width: 100%;
        }
        .MuiGrid-container {
            display: grid;
            grid-template-columns: 1fr;
        }
        .MuiFormControl-root {
            width: 25ch !important;
        }
    }
}
