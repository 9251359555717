.portfolio_menu {
    text-align: center;
    overflow: hidden;
    margin-top: 22px;
    margin-bottom: 48px;
}

.portfolio_menu ul {
    list-style: none outside none;
    text-align: center;
}

.portfolio_menu ul li {
    display: inline-block;
}

.portfolio_menu ul li a {
    color: #363940;
    background: transparent;
    display: inline-block;
    margin: 4px;
    padding: 8px 10px;
    text-decoration: none;
    text-transform: uppercase;
}

.portfolio_single_content {
    padding: 4px;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    margin-bottom: 30px;
    overflow: hidden;
}

.portfolio_single_content:hover img {
    -o-transform: scale(2);
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
}

.portfolio_single_content img {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 6;
    cursor: pointer;
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
}

.portfolio_single_content .canv {
    width: 100% !important;
    height: 100% !important;
}

.portfolio_single_content div + div {
    background: none repeat scroll 0 0 rgba(255, 255, 255, 0.9);
    bottom: -100%;
    color: #ffc000;
    font-weight: bold;
    left: 0;
    margin: 0;
    min-height: 90px;
    padding: 31px 5px 0 10px;
    position: absolute;
    width: 100%;
    z-index: 12;
    border-top: 0;
}

/*.portfolio_single_content:hover div+div{bottom: 0;}*/
.portfolio_single_content div {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    text-align: center;
    transform: scale(0);
    transition: all 0.6s;
    opacity: 0;
}

.portfolio_single_content:hover div {
    transform: scale(1);
    opacity: 1;
}

.portfolio_single_content div a {
    color: #ffa62b;
    display: block;
    font-size: 18px;
    font-weight: bold;
    margin-top: 40%;
    padding-bottom: 10px;
    text-decoration: none;
    text-transform: uppercase;
}

.portfolio_single_content div span {
    color: #ffa62b;
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}

.portfolio_content {
    margin-top: 36px;
    overflow: hidden;
}

/* ===== End Portfolio===== */

/* ===== Begin partners ===== */
#partners-section {
    padding: 80px 0;
}

.partners {
    background-color: #252320;
}

.partners img {
    max-width: 100%;
    padding: 0 15px;
}

/* ===== End partners ===== */

.nav-pills .nav-link {
    font-weight: bold;
    padding-top: 13px;
    text-align: center;
    background: #343436;
    color: #fff;
    /* border-radius: 30px; */
    /* height: 100px; */
}
.nav-pills .nav-link.active {
    background: #fff;
    color: #000;
}
/* .portfolio_content{
  position: absolute;
  width: 450px;
  height: auto;
  margin-top: -50px;
  background: #fff;
  color: #000;
  border-radius: 30px;
  z-index: 1000;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);
  padding: 30px;
  margin-bottom: 50px;
} */
.portfolio_content button {
    border-radius: 15px;
    /* width: 100px; */
    margin: 0 auto;
    float: right;
}
.checkbox-2x {
    transform: scale(2.5);
    -webkit-transform: scale(2.5);
    margin-right: 17px;
    margin-left: 17px;
}
.border-dark-right {
    border-right: black solid 3px;
}
