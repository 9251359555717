$darkBlue: #0a1128;
$whiteSmoke: #e5e5e5;
$golden: #fca311;
$darkPurp: #14213d;
$elHadarGold: #ffa62b;
$elHadarDarkBlue: #363b42;
$elHadarLightBlue: #3d424a;
$elhadarBlueGray: #7c8892;
$mainLightBlue: rgba(138, 177, 185, 1);
$smoke: #f5f5f5;

.ButtonContained {
  height: 5ch;
  width: 15ch;
  background-color: dodgerblue !important;
  color: $whiteSmoke !important;
}
.BigButtonContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2ch;
  padding: 2ch;
  .BigButton {
    min-height: 20vh;
    color: black;
    border-color: black;
  }
}
.LargeButton {
  width: 40%;
  height: 121.667px;
  background: rgba(210, 213, 244, 0.44) !important;
  color: rgb(20, 33, 61) !important;
  font-weight: 600 !important;
  transition: ease-in-out 100s;
  &:hover {
    border: $darkPurp solid 1px;
    filter: saturate(1);
  }
}
.Modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Container {
  background-color: $whiteSmoke;
  padding: 5ch;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #e6e6e6;
  min-height: 80vh;
}
.middleContainer {
  border-radius: 10px;
  box-shadow: 0px 0px 10px #e6e6e6;
  width: 40%;
  margin-top: 5ch;
  align-self: center;
  justify-self: center;
  padding: 5ch;
  min-height: 60vh;
}
.TabContainer {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #e6e6e6;
  min-height: 80vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: overlay;
  &:nth-child(1) {
    resize: horizontal;
  }
  .MuiTabs-scroller {
    width: 100px;
  }
  // .MuiTabPanel-root {
  //     // min-height: fit-content;
  //     // overflow-y: scroll !important;
  // }
}
.NotFound {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  gap: 3ch;
}

.EmployeeDetailContainer {
  .MuiBox-root {
    width: 100%;
  }
}
.id-front {
  background-image: url("../img/id_white.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.BOQ-table {
  th,
  td {
    color: #000;
    border: 1px solid #c3c3c3;
    padding: 12px 35px;
    border-collapse: collapse;
  }
}

@media screen and (max-width: 481px) {
  .EmployeeDetailContainer {
    .MuiBox-root {
      width: 290px;
    }
  }
}

@media print {
  .hide-on-print {
    display: none !important;
  }
  .frame::before {
    img {
      width: 100% !important;
    }
    content: url("../img/Header.png");
    position: absolute;
    top: 0;
    left: 0;
  }
  .frame {
    width: 100% !important;
    img {
      width: 100% !important;
    }
  }
  .frame::after {
    content: url("../img/Footer.png");
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(182, 182, 182);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(129, 129, 129);
}
